import { setCookie, getCookie } from './';

export const setExistingCustomer = () => {
  const hasExistingCustomerCookie = getCookie('hnexistingcustomer');
  if (hasExistingCustomerCookie) {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + 1000 * 60 * 60 * 24 * 365
    );
    setCookie('hnexistingcustomer', true, {
      expires: expirationDate,
    });
  }
};
