export const getCookie = (name) => {
  const doc = typeof document !== 'undefined' ? document : { cookie: '' };

  const pair = doc.cookie.split('; ').find((x) => x.startsWith(`${name}=`));
  if (pair && typeof decodeURIComponent === 'function') {
    // Decode the value
    return decodeURIComponent(pair.split('=')[1]);
  }
  return '';
};
