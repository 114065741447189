import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';
import NewRelic from '~/components/util/NewRelic';

// Redux
import { store } from '~/redux/store';
import { Provider } from 'react-redux';

// Components
import NavHeader from '~/components/sections/Navheader';
import Footer from '~/components/sections/Footer';
import {
  CohesionProvider,
  MonarchPreampPlacement,
} from '@redventures/cohesion-utils-react';

// Providers
import { LegalProvider } from '~/providers/LegalProvider';
import { CallCenterProvider } from '~/providers/CallCenterProvider';

// Helpers
import {
  setMCID,
  setCampaignId,
  getUrlParameter,
  sanitizeNetworkData,
  getOfferEndDiff,
  setExistingCustomer,
} from '~/helpers/utils';
import Script from 'next/script';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';

import listOfPreampAssets from 'preamp/listOfPreampAssets';
// Global CSS - Foundation Grid
import '@red-digital/react-devkit/scss/foundation/foundation.scss';
import '~/scss/bricks.scss';
import { FuseProvider } from '~/providers/FuseProvider';
import { PROMO_END_DATE } from '~/constants/legal-info';

LogRocket.init('0jc5un/hughesnet-cart', {
  release: process.env.release,
  network: {
    requestSanitizer: sanitizeNetworkData(['suggestions']),
    responseSanitizer: sanitizeNetworkData(['suggestions']),
  },
});
function MyApp({ Component, pageProps }) {
  const { events } = useRouter();
  const [updateFuse, setUpdateFuse] = useState(false);
  const [logRocketIdentify, setLogRocketIdentify] = useState(false);
  const currentDate = new Date();
  useEffect(() => {
    const setCookie = (name, value, exmins) => {
      const d = new Date();
      d.setTime(d.getTime() + exmins * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/`;
    };

    setCookie(
      'offerEndDateGap',
      getOfferEndDiff(currentDate, new Date(PROMO_END_DATE)),
      30
    );
  }, []);
  useEffect(() => {
    // Set MarketingCodeId
    setMCID();

    // Set CampaignId
    setCampaignId();

    // Set Existing Customer Cookie
    setExistingCustomer();

    // Executes when route is finish loading
    events.on('routeChangeComplete', () => {
      // Tagular Page View
      window.tagular && window.tagular('pageView');

      // New Relic Page View
      if (window && window.newrelic) newrelic.interaction().save();
    });
  }, []);

  useEffect(() => {
    if (!logRocketIdentify) {
      setLogRocketIdentify(true);
      window?.cohesion('ready', () => {
        LogRocket.identify(window._Cohesion.sessionId);
      });
    }
  }, [logRocketIdentify]);

  useEffect(() => {
    // Set fuse metadata from url params
    if (!updateFuse) {
      setUpdateFuse(true);
      window?.cohesion('fuse:done', () => {
        if (!getUrlParameter('campaignID') && !getUrlParameter('tfn')) {
          return;
        }
        const campaignId = getUrlParameter('campaignID') || '';
        const tfn = getUrlParameter('tfn') || '';
        fuse('meta', { campaignId, tfn });
      });
    }
  }, [updateFuse]);

  return (
    <>
      {/* Initialize Scripts */}
      <NewRelic />
      {process.env.ENVIRONMENT === 'production' && <Script src="/js/gtm.js" />}

      <Provider store={store}>
        <LegalProvider>
          <FuseProvider>
            <CallCenterProvider>
              {/* Home | Header */}
              {/* Without this div, the header will no longer be sticky */}
              <CohesionProvider components={listOfPreampAssets}>
                <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                  <MonarchPreampPlacement
                    placement="11Dkf8jk8NTB736rAZhnP2"
                    componentId={getFilteredComponentName}
                  >
                    <NavHeader />
                  </MonarchPreampPlacement>
                </div>

                <main>
                  <Component {...pageProps} />
                </main>
                {/* Home | Footer */}
                <MonarchPreampPlacement
                  placement="7ndJV7yVZO8e5UyajOqUMF"
                  componentId={getFilteredComponentName}
                >
                  <Footer />
                </MonarchPreampPlacement>
              </CohesionProvider>
            </CallCenterProvider>
          </FuseProvider>
        </LegalProvider>
      </Provider>
    </>
  );
}
// Runs whenever the app initializes
export const reportWebVitals = (metric) => {
  if (process.env.ENVIRONMENT !== 'production') {
    return;
  }

  if (metric.label === 'web-vital') {
    import('~/helpers/utils/cwv.js').then((module) => module.default(metric));
  }
};

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
};

export default MyApp;
